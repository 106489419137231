import React from 'react';
import MainLiveStream from '../../components/stream/MainLiveStream';
import TwitterWidget from '../../components/twitter/TwitterWidget';
import KitUi from '../app/KitUi';
import StreamsPanel from '../panels/steams/StreamsPanel';

const HomePage = () => {
  return (
    <StreamsPanel>
      <div className={'HomeContainer'}>
        <MainLiveStream />
        <TwitterWidget />
      </div>
    </StreamsPanel>
  );
};

export default HomePage;
