import {
  SELECT_MAIN_LIVE_STREAM,
  GET_LIVE_STREAMS,
  GET_LIVE_STREAMS_SUCCESS,
  GET_LIVE_STREAMS_FAILURE,
} from '../actions/StreamActions';

const initialState = {
  mainLiveStream: null,
  liveStreamsListStatus: 'default',
  liveStreamsList: [],
};

const StreamsState = (state = initialState, action) => {
  switch (action.type) {

    case SELECT_MAIN_LIVE_STREAM: {
      const { selectedLiveStream } = action.payload;

      return {
        ...state,
        mainLiveStream: selectedLiveStream,
      }
    }

    case GET_LIVE_STREAMS: {
      return {
        ...state,
        liveStreamsListStatus: 'loading',
      }
    }

    case GET_LIVE_STREAMS_SUCCESS: {
      const { liveStreamsList } = action.payload;

      return {
        ...state,
        liveStreamsListStatus: 'success',
        liveStreamsList,
      }
    }

    case GET_LIVE_STREAMS_FAILURE: {
      return {
        ...state,
        liveStreamsListStatus: 'failure',
      }
    }

    default: {
      return state;
    }
  }
};

export default StreamsState;
