import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImCross } from 'react-icons/im';
import { closeSnackbar } from '../../actions/AppActions';

import './SnackBarProvider.scss';

const SnackBarProvider = ({children}) => {
  const dispatch = useDispatch();

  const { isOpen, type, message } = useSelector(state => state.AppState);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        dispatch(closeSnackbar());
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const setModalClosed = () => dispatch(closeSnackbar());

  const renderSnackBar = () => (
    <div className={'snackBarContainer'}>
      <div className={(type === 'success') ? 'snackbarSuccess' : 'snackbarError'} />
      <div className={'contentContainer'}>
        <div >{message}</div>
        <div onClick={setModalClosed} className={'iconContainer'}>
          <ImCross className={'icon'} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isOpen ? renderSnackBar() : null}
      {children}
    </>
  );
};

export default SnackBarProvider;
