import React from 'react';
import LoaderComponent from './Loader';

const StatusProvider = ({
  status,
  renderDefault,
  renderLoading,
  renderSuccess,
}) => {
   const renderContent = () => {
    switch (status) {
      case 'default':
        return renderDefault ? renderDefault() : null;
      case 'loading':
        return renderLoading ? renderLoading() : <LoaderComponent />;
      case 'success':
        return renderSuccess ? renderSuccess() : null;
      default:
        return null;
    }
  };
   return renderContent();
}

export default StatusProvider;
