import {
  CHECK_USER_SESSION,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAILURE,
} from '../actions/UserActions';
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "../actions/AuthActions";

const initialState = {
  loginStatus: 'default',
  logoutStatus: 'default',
  checkUserSessionStatus: 'default',
  userSessionStatus: false,
  role: 'guest',
  user: {},
};

const UserState = (state = initialState, action) => {
  switch (action.type) {

    case CHECK_USER_SESSION: {
      return {
        ...state,
        checkUserSessionStatus: 'loading',
      };
    }

    case CHECK_USER_SESSION_SUCCESS: {
      const { userSessionStatus, role, user } = action.payload;

      return {
        ...state,
        checkUserSessionStatus: 'success',
        userSessionStatus,
        role,
        user,
      };
    }

    case CHECK_USER_SESSION_FAILURE: {
      return {
        ...state,
        checkUserSessionStatus: 'failure',
      };
    }

    case LOGIN: {
      return {
        ...state,
        loginStatus: 'loading',
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        loginStatus: 'success',
      };
    }

    case LOGIN_FAILURE: {
      return {
        ...state,
        loginStatus: 'failure',
      };
    }

    case LOGOUT: {
      return {
        ...state,
        logoutStatus: 'loading',
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        ...state,
        logoutStatus: 'success',
        userSessionStatus: false
      };
    }

    case LOGOUT_FAILURE: {
      return {
        ...state,
        logoutStatus: 'failure',
      };
    }

    default: {
      return state;
    }
  }
};

export default UserState;
