import { takeLatest, call, put } from 'redux-saga/effects';
import AuthApi from '../api/AuthApi';
import { openSuccessSnackbar, openFailureSnackbar } from '../actions/AppActions';

import {
  CREATE_USER,
  createUserSuccess,
  createUserFailure,
  LOGIN,
  loginSuccess,
  loginFailure,
  LOGOUT,
  logoutSuccess,
  logoutFailure,
} from '../actions/AuthActions';
import { checkUserSession } from '../actions/UserActions';

export default function(apiExecutor) {
  const authApi = new AuthApi(apiExecutor);

  function* createUserSaga(action) {
    const { credentials } = action.payload;

    try {
      const { data, status } = yield call(authApi.createUserApi, credentials);
      console.log('data : ', data);
      if (status === 200) {
        yield put(openSuccessSnackbar(data));
        yield put(createUserSuccess());
      }
    } catch (error) {
      console.error('error auth: ', error);
      yield put(openFailureSnackbar(error.message))
      yield put(createUserFailure());
    }
  }

  function* loginSaga(action) {
    const { credentials } = action.payload;

    try {
      const { data, status } = yield call(authApi.loginApi, credentials);

      if (status === 200) {
        yield put(openSuccessSnackbar());
        yield put(loginSuccess());
        yield put(checkUserSession());
      }
    } catch (error) {
      console.log('error auth: ', error);
      console.log('error auth: ', error.message);
      yield put(openFailureSnackbar(error.message))
      yield put(loginFailure());
    }
  }

  function* logoutSaga() {
    try {
      const { data, status } = yield call(authApi.logoutApi);

      if (status === 200) {
        yield put(logoutSuccess());
        yield put(openSuccessSnackbar(data.message));
      }
    } catch (error) {
      console.error('error auth: ', error);
      yield put(openFailureSnackbar(error.message))
      yield put(logoutFailure());
    }
  }

  return function* watchers() {
    yield takeLatest(CREATE_USER, createUserSaga);
    yield takeLatest(LOGIN, loginSaga);
    yield takeLatest(LOGOUT, logoutSaga);
  };
}
