export const CHECK_USER_SESSION = 'CHECK_USER_SESSION';
export const CHECK_USER_SESSION_SUCCESS = 'CHECK_USER_SESSION_SUCCESS';
export const CHECK_USER_SESSION_FAILURE = 'CHECK_USER_SESSION_FAILURE';

export const checkUserSession = () => ({
  type: CHECK_USER_SESSION,
});

export const checkUserSessionSuccess = (sessionData) => ({
  type: CHECK_USER_SESSION_SUCCESS,
  payload: { ...sessionData },
});

export const checkUserSessionFailure = () => ({
  type: CHECK_USER_SESSION_FAILURE,
});
