import React from 'react';
import { useSelector } from 'react-redux';
import { TwitchEmbed } from 'react-twitch-embed';

import './MainLiveStream.scss';

const MainLiveStream = () => {

  const { mainLiveStream } = useSelector(state => state.StreamsState);

  const renderStream = () => (
    <TwitchEmbed
      channel={mainLiveStream.channel.name}
      id="twitchEmbedComponent"
      theme="dark"
      muted
      width={'80%'}
    />
  );

  const renderNoStream = () => (
    <div className={'mainLiveStreamNoContentContainer'}>
      no live stream
    </div>
  );

  return (
    <div className={'mainLiveStreamContainer'}>
      {mainLiveStream
        ? renderStream()
        : renderNoStream()
      }
    </div>
  );
};

export default MainLiveStream;
