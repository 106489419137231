import { takeLatest, call, put } from 'redux-saga/effects';
import AppApi from '../api/AppApi';
import { openSuccessSnackbar, openFailureSnackbar } from '../actions/AppActions';

import {
  CONTACT_SUPPORT,
  contactSupportSuccess,
  contactSupportFailure,
} from '../actions/AppActions';

export default function (apiExecutor) {
  const appApi = new AppApi(apiExecutor);

  function* contactSupportSaga(action) {
    const { mailContent } = action.payload;

    try {
      const { data, status } = yield call(appApi.contactSupportApi, mailContent);

      if (status === 200) {
        yield put(contactSupportSuccess(data));
        yield put(openSuccessSnackbar(data.message));
      } else {
        yield put(openFailureSnackbar(data.message));
      }

    } catch (error) {
      console.error('error contact support: ', error);
      yield put(openFailureSnackbar(error.message))
      yield put(contactSupportFailure());
    }
  }

  return function* watchers() {
    yield takeLatest(CONTACT_SUPPORT, contactSupportSaga);
  };
}
