import React from 'react';
import App from "./App";
import { Provider } from 'react-redux'
import { store, history } from '../../store';
import { Router } from 'react-router-dom';
// import { PersistGate } from 'redux-persist/integration/react';
import SnackBarProvider from '../snackBar/SnackBarProvider';

const AppProvider = () => (
  <Provider store={store}>
    <Router history={history}>
      <SnackBarProvider>
        <App />
      </SnackBarProvider>
    </Router>
  </Provider>
);

export default AppProvider;

/*
    <PersistGate loading={null} persistor={persistor}>
      </PersistGate>
 */
