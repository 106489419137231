function UserApi(executor) {

  function checkUserSessionApi() {
    return executor.get('/user/check-session');
  }

  return {
    checkUserSessionApi,
  }
}

export default UserApi;
