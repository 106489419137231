import React from 'react';

const Input = ({required, onChange, value, name, label, type, className}) => {
  return (
    <div className={className} style={styles.container}>
      <label style={styles.label}>{label}{required ? '*' : null}</label>
      <input
        onChange={onChange}
        style={styles.input}
        type={type}
        value={value}
        name={name} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: '1em',
    color: 'white',
    margin: '5px 0'
  },
  input: {
    borderRadius: 5,
    border: 'none',
    height: 25,
    fontSize: '15px',
    padding: 5
  }
}

Input.defaultProps = {
  isRequired: false,
  onChange: () => {},
  value: '',
  name: '',
  label: '',
  type: 'text',
}

export default Input;
