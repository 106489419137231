import { all, spawn } from 'redux-saga/effects';
import apiInstance from '../infrastructure/http';
import AppSaga from './AppSaga';
import AuthSaga from './AuthSaga';
import UserSaga from './UserSaga';
import StreamSaga from './StreamSaga';

export const api = apiInstance();

function* sagas() {
  yield all([
    yield spawn(AuthSaga(api)),
    yield spawn(AppSaga(api)),
    yield spawn(UserSaga(api)),
    yield spawn(StreamSaga(api)),
  ]);
}

export default sagas;
