export const OPEN_SUCCESS_SNACKBAR = 'OPEN_SUCCESS_SNACKBAR';
export const OPEN_FAILURE_SNACKBAR = 'OPEN_FAILURE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const openSuccessSnackbar = (message) => ({
  type: OPEN_SUCCESS_SNACKBAR,
  payload: { message },
});

export const openFailureSnackbar = (message) => ({
  type: OPEN_FAILURE_SNACKBAR,
  payload: { message },
});

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
});

export const CONTACT_SUPPORT = 'CONTACT_SUPPORT';
export const CONTACT_SUPPORT_SUCCESS = 'CONTACT_SUPPORT_SUCCESS';
export const CONTACT_SUPPORT_FAILURE = 'CONTACT_SUPPORT_FAILURE';

export const contactSupport = (mailContent) => ({
  type: CONTACT_SUPPORT,
  payload: { mailContent },
});

export const contactSupportSuccess = () => ({
  type: CONTACT_SUPPORT_SUCCESS,
});

export const contactSupportFailure = () => ({
  type: CONTACT_SUPPORT_FAILURE,
});
