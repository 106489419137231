function AppApi(executor) {

  function contactSupportApi(mailContent) {
    return executor.post('/contact/support', { mailContent });
  }

  return {
    contactSupportApi,
  }
}

export default AppApi;
