function StreamApi(executor) {

  function getLiveStreamsApi() {
    return executor.get('/streams/live')
  }

  return {
    getLiveStreamsApi,
  }
}

export default StreamApi;
