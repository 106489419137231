import axios from 'axios';
const { REACT_APP_BASE_URL } = process.env;

export default function apiInstance(options) {
  const instance = axios.create({
    baseURL: REACT_APP_BASE_URL,
    timeout: 10000,
    withCredentials: true,
    ...options,
  });

  return instance;
}
