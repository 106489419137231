import React from 'react';
import Loader from 'react-loader-spinner';

export const LoaderComponent = () => (
  <Loader
    type={'TailSpin'}
    color={'red'}
    height={32}
    width={32}
  />
);

export default LoaderComponent;
