export const SELECT_MAIN_LIVE_STREAM = 'SELECT_MAIN_LIVE_STREAM';

export const selectMainLiveStream = (selectedLiveStream) => ({
  type: SELECT_MAIN_LIVE_STREAM,
  payload: { selectedLiveStream },
});

export const GET_LIVE_STREAMS = 'GET_LIVE_STREAMS';
export const GET_LIVE_STREAMS_SUCCESS = 'GET_LIVE_STREAMS_SUCCESS';
export const GET_LIVE_STREAMS_FAILURE = 'GET_LIVE_STREAMS_FAILURE';

export const getLiveStreams = () => ({
  type: GET_LIVE_STREAMS,
});

export const getLiveStreamsSuccess = (liveStreamsList) => ({
  type: GET_LIVE_STREAMS_SUCCESS,
  payload: { liveStreamsList },
});

export const getLiveStreamsFailure = () => ({
  type: GET_LIVE_STREAMS_FAILURE,
});
