import React, { useState, useRef } from 'react';
import Input from '../../components/input/Input';
import OutsideClick from '../../services/OutsideClick';
import { login, createUser } from '../../actions/AuthActions';
import { useDispatch } from 'react-redux';
import { RiTwitchLine } from 'react-icons/ri';
import { ImCross } from 'react-icons/im';
import clsx from 'clsx';

import './Login.scss';

const { REACT_APP_BASE_URL } = process.env;

const Login = ({openLoginMenu}) => {
  const dispatch = useDispatch();

  const loginModalRef = useRef(null);

  const [menuType, setMenuType]= useState('login');
  const [credentials, setCredentials] = useState({identity: '', password: ''});
  const [createCredentials, setCreateCredentials] = useState({
    email: '',
    pseudo: '',
    password: '',
    confirmPassword: '',
  });

  OutsideClick(loginModalRef, openLoginMenu);

  const handleLoginChange = ({target: { value, name }}) => setCredentials({ ...credentials, [name]: value });

  const handleCreateChange = ({target: { value, name }}) => setCreateCredentials({ ...createCredentials, [name]: value });

  const getLogin = () => {
    openLoginMenu();
    dispatch(login(credentials));
  };

  const setCreateUser = () => {
    if (createCredentials.password === createCredentials.confirmPassword) {
      dispatch(createUser(createCredentials));
      setCreateCredentials({
        email: '',
        pseudo: '',
        password: '',
        confirmPassword: '',
      });
      openLoginMenu();
    }
  };

  const cancelModal = () => openLoginMenu();

  const chooseMenuType = (type) => () => setMenuType(type);

  const renderLogin = () => (
    <>
      <Input
        label={'Identifiant'}
        required
        className={'loginInput'}
        onChange={handleLoginChange}
        name={'identity'}
        value={credentials.identity} />
      <Input
        label={'Password'}
        className={'loginInput'}
        required
        onChange={handleLoginChange}
        name={'password'}
        value={credentials.password} />
      <button className={'loginButton'} onClick={getLogin}>
        Se connecter
      </button>
    </>
  );

  const renderRegister = () => (
    <>
      <Input
        label={'Email'}
        required
        className={'loginInput'}
        onChange={handleCreateChange}
        name={'email'}
        value={createCredentials.email} />
      <Input
        label={'Pseudo'}
        required
        className={'loginInput'}
        onChange={handleCreateChange}
        name={'pseudo'}
        value={createCredentials.pseudo} />
      <Input
        label={'Password'}
        className={'loginInput'}
        required
        onChange={handleCreateChange}
        name={'password'}
        value={createCredentials.password} />
      <Input
        label={'Confirm password'}
        className={'loginInput'}
        required
        onChange={handleCreateChange}
        name={'confirmPassword'}
        value={createCredentials.confirmPassword} />
      <button className={'loginButton'} onClick={setCreateUser}>
        Créer
      </button>
    </>
  )

  return (
    <div className={'loginContainer'}>
      <div ref={loginModalRef} className={'LoginFormContainer'}>
        <ImCross onClick={cancelModal} className={'loginCloseIcon'} />
        <h1>Login</h1>
        <div className={'registerLoginChoice'}>
          <div onClick={chooseMenuType('login')}>
            <p>Se Connecter</p>
            <div className={clsx('loginBorder',
              menuType === 'login' ? 'selected' : '',
            )} />
          </div>
          <div onClick={chooseMenuType('register')}>
            <p>S'inscrire</p>
            <div className={clsx('loginBorder',
              menuType === 'register' ? 'selected' : '',
            )} />
          </div>
        </div>
        {menuType === 'login' ? renderLogin() : renderRegister()}
        <div className={'separator'} />
        <a href={`${REACT_APP_BASE_URL}/user/auth/twitch`}>
          <button className={'twitchConnexionButton'}>
            Se connecter
            <RiTwitchLine className={'icon'} />
          </button>
        </a>
      </div>

    </div>
  );
};

export default Login;
