import { combineReducers } from 'redux';
import AppState from './AppReducer';
import UserState from './UserReducer';
import StreamsState from './StreamsReducer';

const reducer = combineReducers({
  AppState,
  UserState,
  StreamsState,
});

export default reducer;
