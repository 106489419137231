import { takeLatest, call, put } from 'redux-saga/effects';
import StreamApi from '../api/StreamApi';
import { openSuccessSnackbar, openFailureSnackbar } from '../actions/AppActions';

import {
  GET_LIVE_STREAMS,
  getLiveStreamsSuccess,
  getLiveStreamsFailure,
  selectMainLiveStream,
} from '../actions/StreamActions';

export default function (apiExecutor) {
  const streamApi = new StreamApi(apiExecutor);

  function* getLiveStreamsSaga() {

    try {
      const { data, status } = yield call(streamApi.getLiveStreamsApi);

      if (status === 200) {
        yield put(getLiveStreamsSuccess(data));

        if (data.length !== 0) {
          const mainLive = data.sort((a, b) => b.viewers - a.viewers)[0];
          yield put(selectMainLiveStream(mainLive));
        }
      }

    } catch (error) {
      console.error('error auth: ', error);
      yield put(openFailureSnackbar(error.message))
      yield put(getLiveStreamsFailure());
    }
  }

  return function* watchers() {
    yield takeLatest(GET_LIVE_STREAMS, getLiveStreamsSaga);
  };
}
