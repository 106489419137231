import React, { useState, useEffect } from 'react';
import { Nemerian_brand, Logo } from '../../assets/pictures';
import { FaRegUserCircle, FaUser } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { RiTwitchLine } from 'react-icons/ri';
import { FiLogOut, FiLogIn } from 'react-icons/fi';
import { logout } from '../../actions/AuthActions';
import Login from '../login/Login';

import './Header.scss';

const Header = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const { userSessionStatus } = useSelector(state => state.UserState);

  const getlogout = () => dispatch(logout());

  const openMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const bodyStyle = document.querySelector('body').style;
    if (isLoginOpen) {
      bodyStyle.overflow = 'hidden';
    } else {
      bodyStyle.overflow = 'initial';
    }
  }, [isLoginOpen]);

  const openLoginMenu = () => {
    setIsOpen(false);
    setIsLoginOpen(!isLoginOpen);
  };

  const renderConnexionMenu = () => (
    <div onClick={openLoginMenu} className={'connexionMenuContainer'}>
      <FiLogIn className={'loginIcon'} />
      connexion
    </div>
  );

  const renderConnexionIcon = () => (
    <>
      <FaUser onClick={openMenu} className={'headerIcon'} />
      {isOpen ? renderConnexionMenu() : null}
    </>
  );

  return (
    <header className={'headerContainer'}>
      <div className={'left-menu'}>
        <a href={'/'}>
          <img alt={'brand'} className={'brand'} src={Nemerian_brand} />
        </a>
      </div>
      <div className={'right-menu'}>
        {userSessionStatus
          ? (<Link to={`/contact`}>
              <BiMailSend className={'headerIcon'}/>
            </Link>
          ): null}
        {userSessionStatus
          ? <FiLogOut onClick={getlogout} className={'headerIcon'} />
          : renderConnexionIcon()
        }
      </div>
      {isLoginOpen ? <Login openLoginMenu={openLoginMenu} /> : null}
    </header>
  );
};

export default Header;
