import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { persistStore, persistReducer } from 'redux-persist';
import reducers from './reducers';
import sagas from './sagas';
// import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

// const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export {
  store,
  // persistor,
  history,
};
