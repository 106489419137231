import { takeLatest, call, put } from 'redux-saga/effects';
import UserApi from '../api/UserApi';
import { openSuccessSnackbar, openFailureSnackbar } from '../actions/AppActions';

import {
  CHECK_USER_SESSION,
  checkUserSessionSuccess,
  checkUserSessionFailure,
} from '../actions/UserActions';

export default function (apiExecutor) {
  const userApi = new UserApi(apiExecutor);

  function* checkUserSessionSaga() {

    try {
      const { data, status } = yield call(userApi.checkUserSessionApi);
      if (status === 200) {
        yield put(checkUserSessionSuccess(data));
        yield put(openSuccessSnackbar(data.message));
      }

    } catch (error) {
      console.error('error auth: ', error);
      yield put(openFailureSnackbar(error.message))
      yield put(checkUserSessionFailure());
    }
  }

  return function* watchers() {
    yield takeLatest(CHECK_USER_SESSION, checkUserSessionSaga);
  };
}
