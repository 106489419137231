import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactSupport } from '../../actions/AppActions';
import Input from '../../components/input/Input';
import TextArea from '../../components/input/TextArea';
import LoaderComponent from '../../components/statusProvider/Loader';

import './Contact.scss';

const mailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

const Contact = () => {
  const dispatch = useDispatch();

  const [formError, setFormError] = useState(false);
  const [mailContent, setMailContent] = useState({
    mail: '',
    subject: '',
    content: '',
  });

  const { sendMailStatus } = useSelector(state => state.AppState);

  useEffect(() => {
    if (formError) {
      const timer = setTimeout(() => {
        setFormError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [formError]);

  useEffect(() => {
    if (sendMailStatus === 'success') {
      setMailContent({
        mail: '',
        subject: '',
        content: '',
      });
    }
  }, [sendMailStatus]);

  useEffect(() => {
    if (formError) {
      const timer = setTimeout(() => {
        setFormError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [formError]);

  const handleChange = ({target: {name, value}}) => {
    setMailContent({
      ...mailContent,
      [name]: value,
    })
  };

  const onSubmit = () => {
    const checkInputs = Object.values(mailContent).filter(item => !item).length === 0;
    const checkEmail = mailRegex.test(mailContent.mail);

    if (checkInputs && checkEmail) {
      dispatch(contactSupport(mailContent));
    } else {
      setFormError(true);
    }
  }

  const renderButton = () => (
    <button className={'submitButton'} onClick={onSubmit}>
      {sendMailStatus === 'loading' ? <LoaderComponent /> : 'Submit'}
    </button>
  );


  return (
    <div className={'contactPageContainer'}>
      <div className={'formContainer'}>
        <div className={'inputContainer'}>
          <Input
            className={'formInput'}
            onChange={handleChange}
            required
            label={'Your email address'}
            type={'email'}
            name={'mail'}
            value={mailContent.mail} />
          <Input
            className={'formInput'}
            onChange={handleChange}
            required
            label={'Contact reason'}
            name={'subject'}
            value={mailContent.subject} />
        </div>
        <TextArea
          className={'formInput'}
          onChange={handleChange}
          required
          label={'Your message'}
          name={'content'}
          value={mailContent.content} />
          <div className={'formFooter'}>
            {renderButton()}
            {formError ? <p className={'formError'}>Empty input or bad email</p> : null}
          </div>
      </div>
    </div>
  );
};

export default Contact;

