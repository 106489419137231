import React, { useState } from 'react';
import { BiArrowFromLeft, BiArrowFromRight } from 'react-icons/bi';
import { liveStreamStubs } from '../../../stubs/LiveSteams';
import { selectMainLiveStream } from '../../../actions/StreamActions';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import StatusProvider from '../../../components/statusProvider/StatusProvider';

import './StreamsPanel.scss';

const StreamsPanel = ({children}) => {
  const dispatch = useDispatch();

  const { mainLiveStream, liveStreamsListStatus, liveStreamsList } = useSelector(state => state.StreamsState);

  const [isOpen, setIsOpen] = useState(false);

  const getLiveStreams = (selectedStream) => () => dispatch(selectMainLiveStream(selectedStream));

  const openPanel = (bool) => () => setIsOpen(bool);

  const renderStreamsList = () => liveStreamsList.map((item) => (
    <div key={item.channel._id} onClick={getLiveStreams(item)} className={'liveStreamItem'}>
      <img alt={'streamIcon'} className={'liveStreamIcon'} src={item.channel.logo} />
      {isOpen ? <p>{item.channel.name}</p> : null}
    </div>
  ));

  const renderOpenMenuArrow = () => {
    return (
      <div className={'openMenuTitleContainer'}>
        <p>ONLINE</p>
        <BiArrowFromRight className={'panelIcon'} onClick={openPanel(false)} />
      </div>
    );
  };

  const renderContent = () => (
    <>
      <div className={clsx('panelIconContainer', isOpen ? 'openPanel' : '')}>
        {isOpen
          ? renderOpenMenuArrow()
          : <BiArrowFromLeft className={'panelIcon'} onClick={openPanel(true)} />
        }
      </div>
      <div className={'liveStreamList'}>
        {renderStreamsList()}
      </div>
    </>
  );

  return (
    <div className={'streamsPanelContainer'}>
      <div className={clsx('streamsPanelContentContainer', isOpen ? 'openPanel' : '')}>
        <StatusProvider
          status={liveStreamsListStatus}
          renderSuccess={renderContent} />
      </div>
      <div className={'streamsPanelChildrenContainer'}>
        {children}
      </div>
    </div>
  );
};

export default StreamsPanel;
