import React, { useEffect, useState } from 'react';
import { Timeline } from 'react-twitter-widgets';
import LoaderComponent from '../statusProvider/Loader';
import clsx from 'clsx';

import './TwitterWidget.scss';

const TwitterWidget = () => {

  const [largeScreen, setLargeScreen] = useState(true);
  const [widgetReady, setWidgetReady] = useState(false);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1000) {
      setLargeScreen(true)
    } else {
      setLargeScreen(false)
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', changeWidgetWith);

    return () => window.removeEventListener('resize', changeWidgetWith);
  }, [largeScreen]);

  const changeWidgetWith = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1000 && !largeScreen) {
      setLargeScreen(true);
      setWidgetReady(false);
    } else if (windowWidth < 1000 && largeScreen) {
      setLargeScreen(false);
      setWidgetReady(false);
    }
  };

  return (
    <section className={'twitterWidgetContainer'}>
      <div className={clsx('twitterWidget', widgetReady ? 'open' : '')}>
        <Timeline
          dataSource={{ sourceType: 'profile', screenName: 'Nemerian1' }}
          onLoad={() => setWidgetReady(true)}
          options={{ chrome: 'noheader, nofooter', theme: 'dark', width: `${largeScreen ? 600 : 300}`, height: '400', borderRadius: '25' }} />
      </div>
    </section>
  );
}

export default TwitterWidget;
