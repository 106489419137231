import {
  OPEN_SUCCESS_SNACKBAR,
  OPEN_FAILURE_SNACKBAR,
  CLOSE_SNACKBAR,
  CONTACT_SUPPORT,
  CONTACT_SUPPORT_SUCCESS,
  CONTACT_SUPPORT_FAILURE,
} from '../actions/AppActions';

const initialState = {
  isOpen: false,
  type: null,
  message: null,
  sendMailStatus: 'default',
};

const AppState =  (state = initialState, action) => {
  switch (action.type) {

    case OPEN_SUCCESS_SNACKBAR: {
      const { message } = action.payload;

      return {
        ...state,
        isOpen: true,
        type: 'success',
        message,
      };
    }

    case OPEN_FAILURE_SNACKBAR: {
      const { message } = action.payload;

      return {
        ...state,
        isOpen: true,
        type: 'error',
        message,
      };
    }

    case CLOSE_SNACKBAR: {
      return {
        ...initialState,
      };
    }

    case CONTACT_SUPPORT: {
      return {
        ...state,
        sendMailStatus: 'loading',
      }
    }

    case CONTACT_SUPPORT_SUCCESS: {
      return {
        ...state,
        sendMailStatus: 'success',
      }
    }

    case CONTACT_SUPPORT_FAILURE: {
      return {
        ...state,
        sendMailStatus: 'failure',
      }
    }

    default: {
      return state;
    }
  }
};

export default AppState;
