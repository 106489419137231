import qs from 'qs';

function AuthApi(executor) {

  function createUserApi(credentials) {
    return executor.post('/user/create', credentials)
  }

  function loginApi(credentials) {
    return executor.post('/user/auth/login', qs.stringify(credentials));
  }

  function logoutApi() {
    return executor.post('/user/auth/logout');
  }

  return {
    createUserApi,
    loginApi,
    logoutApi,
  }
}

export default AuthApi;
