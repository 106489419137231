import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserSession } from '../../actions/UserActions';
import { getLiveStreams } from '../../actions/StreamActions';
import { Route, Switch } from "react-router-dom";
import HomePage from '../home/HomePage';
import Header from '../header/Header';
import Contact from '../contact/Contact';
import { useHistory } from 'react-router-dom';

import './App.scss';

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userSessionStatus } = useSelector(state => state.UserState);

  useEffect(() => {
    const isGuestPage = history.location.pathname === '/';

    if (!userSessionStatus && !isGuestPage) {
      history.push('/');
    }
  }, [userSessionStatus]);

  useEffect(() => {
    dispatch(checkUserSession());
    dispatch(getLiveStreams());
  }, []);

  const renderSwitch = () => (
    <Switch>
      <Route exact path={'/'} component={HomePage} />
      {userSessionStatus ? renderConnectedSwicth() : null}
    </Switch>
  );

  const renderConnectedSwicth = () => (
    <>
      <Route exact path={'/contact'} component={Contact} />
    </>
  )

  return (
    <>
      <Header />
      {renderSwitch()}
    </>
  )
}

export default App;
