import React from 'react';

const TextArea = ({required, onChange, value, name, label, className}) => {
  return (
    <div className={className} style={styles.container}>
      <label style={styles.label}>{label}{required ? '*' : null}</label>
      <textarea
        onChange={onChange}
        rows={5}
        value={value}
        style={styles.textArea}
        name={name} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5
  },
  label: {
    fontSize: '1em',
    color: 'white',
    margin: '5px 0'
  },
  textArea: {
    resize: 'none',
    borderRadius: 5,
    fontSize: '15px',
    border: 'none',
    padding: 5
  }
}

TextArea.defaultProps = {
  isRequired: false,
  onChange: () => {},
  value: '',
  name: '',
  label: '',
  type: 'text',
}

export default TextArea;
